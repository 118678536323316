














import { Vue, Component } from 'vue-property-decorator'
import { Modal, Steps, HttpPost, Loader, Card } from '../../../lib/mavejs'
import SearchCampaignForm from '@/views/campaigns/searchForm/SearchCampaignForm.vue'
import SearchTargetingForm from '@/views/campaigns/searchForm/SearchTargetingForm.vue'
import SearchGeoForm from '@/views/campaigns/searchForm/SearchGeoForm.vue'
import SearchOptionsForm from '@/views/campaigns/searchForm/SearchOptionsForm.vue'
import SearchAdsForm from '@/views/campaigns/searchForm/SearchAdsForm.vue'
import { url } from '@/functions'
import UrlCheck from '@/components/campaigns/form/UrlCheck.vue'

@Component({ components: { UrlCheck, Modal, Steps, HttpPost, Loader, Card }, methods: { url } })
export default class CreateSearchCampaign extends Vue {
  steps = [
    { icon: 'analytics', label: 'Campaign', component: SearchCampaignForm },
    { icon: 'people_alt', label: 'Targeting', component: SearchTargetingForm },
    { icon: 'flag', label: 'Geo\'s', component: SearchGeoForm },
    { icon: 'post_add', label: 'Options', component: SearchOptionsForm },
    { icon: 'featured_video', label: 'Ads', component: SearchAdsForm }
  ]

  isFetching = false

  $refs: {
    submit: HttpPost;
    check: UrlCheck;
  }

  submit () {
    this.$refs.submit.post()
      .then(() => {
        this.$store.commit('clearForm', { name: 'search' })
        this.$router.push('/campaigns?refresh=true')
      })
  }
}
